<template>
    <div class="q-setting">
        <div class="q-setbtns">
             <a href="javaScript:void(0)" class="setbtn" :class="{'active':(settingType==3)}" @click="activeSetting(3)" >设置悬赏<i class="expand-icon"></i></a>
            <a href="javaScript:void(0)" class="setbtn" :class="{'active':(settingType==2)}" @click="activeSetting(2)">查看分成<i class="expand-icon"></i></a>
            <a href="javaScript:void(0)" class="setbtn" :class="{'active':(settingType==4)}" @click="activeSetting(4)" >求解方式<i class="expand-icon"></i></a>
        </div>
        <div class="set-content">
            <!-- 设置悬赏 -->
            <div class="set-shared" v-show="settingType==3" style="height:300px">
                <i class="shared-arrow" style="margin-left:130px"><em></em></i>
                <!-- 选项 -->
                <div class="shared-content">
                    <div class="select-btn-group1">
                        <a href="javaScript:void(0)" class="select-btn" style="width:86px" :class="{'active':(reward.type==1)}" @click="activeSharedse(1,'type','reward')" >免费</a>    
                       <!-- 竞价成交 -->
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:86px"  :class="{'active':(reward.type==2)}" @click="activeShared(2,'type','reward')">竞价成交</a>
                            <div class="select-tip" v-show="reward.type==2">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">问题竞价不超过 <input type="number" style="width:150px" oninput="if(value.length > 10)value = value.slice(0, 10)" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"  min="1" v-model="reward.pay">知识币</div>
                                        <div class="fill-d">悬赏截止时间：
                                                    <el-date-picker
                                                    v-model="reward.endTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                     class="sss"
                                                      format="yyyy-MM-dd HH:mm"
                                                    >
                                                </el-date-picker>
                                             <!-- <input type="datetime-local" class="size" v-model="reward.endTime"> -->
                                             </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 抢答成交 -->
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:86px"  :class="{'active':(reward.type==3)}" @click="activeShared(3,'type','reward')">抢答成交</a>
                            <div class="select-tip" v-show="reward.type==3">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">问题付费 <input type="number" style="width:150px"  oninput="if(value.length > 10)value = value.slice(0, 10)" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')" min="1" v-model="reward.pay">知识币</div>
                                         <div class="fill-d">悬赏截止时间：
                                              <el-date-picker
                                                    v-model="reward.endTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm"
                                                     class="sss"
                                                    >
                                                </el-date-picker>
                                             <!-- <input type="datetime-local" class="size" v-model="reward.endTime"> -->
                                             </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 信用度成交 -->
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:86px"  :class="{'active':(reward.type==4)}" @click="activeShared(4,'type','reward')">信用度成交</a>
                            <div class="select-tip" v-show="reward.type==4">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">问题付费 <input type="number" style="width:150px" oninput="if(value.length > 10)value = value.slice(0, 10)" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"  min="1" v-model="reward.pay">知识币</div>
                                         <div class="fill-d">悬赏截止时间：
                                              <el-date-picker
                                                    v-model="reward.endTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm"
                                                     class="sss"
                                                    >
                                                </el-date-picker>
                                             <!-- <input type="datetime-local" class="size" v-model="reward.endTime"> -->
                                             </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                        
                        <!-- 选择成交 -->
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:86px"  :class="{'active':(reward.type==5)}" @click="activeShared(5,'type','reward')">选择成交</a>
                            <div class="select-tip" v-show="reward.type==5">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">问题付费 <input type="number" style="width:150px" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"  v-model="reward.pay">知识币</div>
                                         <div class="fill-d">悬赏截止时间：
                                              <el-date-picker
                                                    v-model="reward.endTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm"
                                                     class="sss"
                                                    >
                                                </el-date-picker>
                                              <!-- <input type="datetime-local" class="size" v-model="reward.endTime"> -->
                                              </div>
                                    </div>
                                </div>
                            </div>
                        </div>                      
                    </div>
                </div>
            </div>
            <!-- 查看分成 -->
            <div class="set-shared" v-show="settingType==2">
                <i class="shared-arrow"><em></em></i>
                <!-- 选项 -->
                <div class="shared-content">
                    <div class="select-btn-group">
                        <a href="javaScript:void(0)" class="select-btn" style="width:85px" :class="{'active':(shared.type==1)}" @click="activeShared(1,'type','shared')" >    免费    </a>    
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:85px"  :class="{'active':(shared.type==2)}" @click="activeShareds(2,'type','shared')">    分成    </a>
                            <div class="select-tip" v-show="shared.type==2">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">问题付费 <input type="number" style="width:150px" min="1" oninput="if(value.length > 10)value = value.slice(0, 10)"  onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"  v-model="shared.pay">知识币</div>
                                         <div class="fill-d" >分成比例：
                                             <span >问题发布者：<input type="number" min="1" max="100" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')" onblur="value=value.replace(/[^\d]/g,'')" v-on:input="oninputs()" v-model="shared.release">%</span>
                                             <span style="margin-left:5px;margin-right:5px;font-weight: bold;">,</span>
                                             <span>被采纳者：<input type="number" min="1" max="100" onkeyup="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')" onblur="value=value.replace(/[^\d]/g,'')" v-model="shared.answer" v-on:input="oninput()">%</span>
                                             <div style="text-align: center;margin-top:5px;font-size: 14px;font-family: Microsoft YaHei;font-weight: 300;color: #acacac;">（问题发布者和回答被采纳者的比例和为100）</div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 求解方式 -->
            <div class="set-shared" v-show="settingType==4">
                <i class="shared-arrow" style="margin-left:250px"><em></em></i>
                <!-- 选项 -->
                <div class="shared-content">
                    <div class="select-btn-group2">
                        <a href="javaScript:void(0)" class="select-btn" style="width:85px" :class="{'active':(solve.type==1)}" @click="activeShared(1,'type','solve')" >线上求解</a>    
                        <div class="tip-btn" >
                            <a href="javaScript:void(0)" class="select-btn" style="width:85px"  :class="{'active':(solve.type==2)}" @click="activeShared(2,'type','solve')">线下求解</a>
                            <div class="select-tip" v-show="solve.type==2">
                                <i class="select-tip-arrow"><em></em></i>
                                <div>
                                    <div class="fill-content">
                                        <div class="fill-d">求解开始时间 
                                                <el-date-picker
                                                    v-model="solve.startTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm"
                                                     class="sss"
                                                    >
                                                </el-date-picker>
                                            <!-- <input type="datetime-local" v-model="solve.startTime"> -->
                                            </div>
                                        <div class="fill-d">求解结束时间 
                                                <el-date-picker
                                                    v-model="solve.endTime"
                                                    type="datetime"
                                                    placeholder="选择日期"
                                                    format="yyyy-MM-dd HH:mm"
                                                     class="sss"
                                                    >
                                                </el-date-picker>
                                            <!-- <input type="datetime-local" v-model="solve.endTime"> -->
                                            </div>
                                        <div class="fill-d">求解地点 <input type="text" style="width:300px" v-model="solve.adress"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ask-question-contener"  v-show="isModifyQuestion">
               <div class="close-btn">
                   <div >
                       <span @click.stop="leavePage">关闭</span>
                   </div>
               </div>
             
            </div>
        </div>

    </div>
</template>

<script>

export default {

    data(){
        return{
            settingType:0, //设置方式 1-修改问题  2-设置悬赏  3-查看分成  4-求解方式 
            isModifyQuestion:false,
       }
    },
    props:{
        shared:{
            type:Object,
            required:true
        },
        reward:{
            type:Object,
            required:true
        },
        solve:{
            type:Object,
            required:true
        }
    },
    methods:{
        // 问题回答者比例的输入
        async oninput (){
           if(this.shared.answer>100){
            this.$message({
                      message: "查看分成的比例不能超过100哦！",
                      type: "warning",
                    });
               this.shared.answer=0;
           }
           else{
               this.shared.release=100-Number(this.shared.answer)
            //    console.log("发布者"+this.shared.release)
           }
        },
        // 问题采纳者比例的输入
        async oninputs (){
           if(this.shared.release>100){
              this.$message({
                      message: "查看分成的比例不能超过100哦！",
                      type: "warning",
                    });
              this.shared.release=0;       
           }
           else{
               this.shared.answer=100-Number(this.shared.release)
            //    console.log("回答者"+this.shared.release)
           }
        },
        activeShared(typeValue,typeField,set){
            this[set][typeField]=typeValue;  
        },
          activeShareds(typeValue,typeField,set){
       if(this.reward.type==1)
       {
         this.$alert('查看分成的问题不能为免费哦！', '系统提示：', {
                       dangerouslyUseHTMLString: true
                      });
       }
       else{
           this[set][typeField]=typeValue;
       }

        },
    activeSharedse(typeValue,typeField,set){
       if(this.shared.type==2)
       {
         this.$alert('查看分成的问题不能为免费哦！', '系统提示：', {
                       dangerouslyUseHTMLString: true
                      });
       }
       else{
           this[set][typeField]=typeValue;
       }

        },
        activeSetting(typeValue){
            this.settingType=typeValue;
        }
        ,
        leavePage(){
          let isLeave= confirm("是否离开修改页面");
          if(isLeave==true){
              this.isModifyQuestion=false;
          }
        },
        //分成确认
        submitShared(){
        },
        //悬赏确认
        submitReward(){

        },
        //求解方式确认
        submitSolve(){

        }
    }
}
</script>

<style scoped>
   input{
    height: 22px;
    font-size: 11px;
   }
   input:focus {
   outline:none;
   border: 1px solid #9ac1f5;
   box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
   /* border-width :0 0 1px 0 ; */
   }
   .q-setbtns .setbtn{
    display: inline-block;
    padding: 0 15px;
    width: 60px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    color: #0084FF;
    border: 1px solid #0084FF;
    border-radius: 3px;
    text-decoration: none;
    
   }
   .q-setbtns .publish{
       width: 60px;
       background-color: #0084FF;
       color: #fff;
       float: right;
       
   }
   .q-setbtns .setbtn:not(:first-of-type){
       margin-left: 10px;
   }
   .q-setbtns .setbtn i{
       margin-left: 5px;
   }
   .set-shared{
       /* position: relative;
      height: 200px;
       border: 1px solid #ebf0f1;
    border-radius: 3px;
    margin-top: 20px; */
   }
   .shared-content{
       /* border: 1px solid red; */
   }
   .shared-arrow{
    position: absolute;
    z-index: 2;
    top: -8px;
    left: 48px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ebf0f1;
   }
 .shared-arrow em{
    width: 0;
    height: 0;
    position: absolute;
    left: -6px;
    top: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid #f9fbfb;
 }
 .select-btn{
    display: inline-block;
    padding: 0 15px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    color: #0084FF;
    border: 1px solid #0084FF;
    border-radius: 3px;
    text-decoration: none;
 }
 .fill-d{
     width: 100%;
     height: 40px;
     /* border:1px solid red */
 }
 .fill-d,.currency-kind{
     display: inline-block;
 }
 .currency-kind{
     margin-left: 5px;
     width: 80px;
 }
 .currency-kind .select-btn{
     width: 100%;
 }
 .active{
     color: #fff !important;
     background-color:#0084FF !important;
 }
 .select-btn-group{
     /* border: 1px solid red; */
     width: 90px;
     margin-top: 2px;
     display:inline-block;
          position: relative;
     left: 102px;
     /* margin-left: 50px; */
 }
  .select-btn-group1{
     /* border: 1px solid red; */
     width: 90px;
      margin-top: 2px;
     display:inline-block;
     /* position: relative;
     left: 102px; */
     /* margin-left: 50px; */
 }
   .select-btn-group2{
     /* border: 1px solid red; */
     width: 90px;
      margin-top: 2px;
     display:inline-block;
     position: relative;
     left: 204px;
     /* margin-left: 50px; */
 }
.fill-content .fill-d input{
    border-width :0 0 1px 0 ;
    outline:none;
}
.submitBtn{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
}
 .submitBtn .select-btn{
    margin-right: 20px;
     margin-bottom: 20px;
} 

.select-tip{
    position:absolute;
    width: 400px;
    left:100%;
    top:0;
    margin-left:15px ;
    background: #FFF;
    min-width: 400px;
    border: 1px solid #EBEEF5;
    padding: 12px;
    z-index: 2000;
    color: #606266;
    line-height: 1.4;
    text-align: justify;
    font-size: 14px;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    word-break: break-all;

}
.select-tip-arrow{
    position: absolute;
    z-index: 2;
    top: 15%;
    left: -9px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid  #EBEEF5;
    border-bottom: 8px solid  transparent;
}
.select-tip-arrow em{
    width: 0;
    height: 0;
    position: absolute;
    left: 2px;
    top: -6px;
    border-top: 6px solid transparent;
    border-right: 7px solid #FFF;
    border-bottom: 6px solid transparent;
}
.tip-btn{
    height:26px;position:relative;display:inline-block
}
.ask-question-contener{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5)
}
.close-btn{
    width:100%; 
    position:absolute; 
    top:50px;
}
.close-btn>div{
    width:886px;
    height:30px; 
    margin:0 auto;
    text-align:right 
}
.close-btn>div>span{
    margin-right:10px;
    cursor: pointer;
}
.close-btn>div>span:hover{
   color: red;
}
.size{
    border: none;
    border-bottom: 1px solid  #565656
}
.sss{
    border: none;
    cursor: pointer;
    
}
::v-deep .el-icon-time:before{
content: none;
}
</style>