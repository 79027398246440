<template>
<div class="center">
 
<div class="askq-content-wrap" style="margin:0px auto;right:0px;left:0px">   
    <div class="center1"></div>
                    <div class="newTip-box">
                        <div class="new-tip-content">
                            <div class="title top-title">如何提问才能更快得到有帮助的答案？</div>
                            <div class="co">智荟上的提问应遵循<span>真实、完整、明确、简洁</span>的原则。</div>
                            <div class="co-li">
                                <div class="li"><span>真实</span>: 自己的真实疑惑，希望得到帮助；</div>
                                <div class="li"><span>完整</span>: 问句完整，以“？”结束，让别人看得懂；</div>
                                <div class="li"><span>明确</span>: 有针对性的提问，描述不模糊，阐明要点；</div>
                                <div class="li"><span>简洁</span>: 不赘述，问题一句话，描述1～3句说清楚；</div>
                            </div>

                            <div class="title">一个好提问需要包含如下信息：</div>
                            <div class="right-co">
                                <div class="r-li">
                                    <span class="r-icon"></span>
                                    <div>问题是什么：说出你想得到的帮助，每次只问一件事，以“？”结束。</div>
                                </div>
                                <div class="r-li">
                                    <span class="r-icon"></span>
                                    <div>具体情况： 目前“你”遇到的问题情况描述，你对遇到现状和问题的理解和观点。</div>
                                </div>
                                <div class="r-li">
                                    <span class="r-icon"></span>
                                    <div>时间信息： 你的问题是什么时间出现的，持续多久，希望在什么时间被解决？</div>
                                </div>
                                <div class="r-li">
                                    <span class="l-r-icon"></span>
                                    <div>为什么越来越多的人把宠物当家人？</div>
                                </div>
                                <div class="r-li">
                                    <span class="l-r-icon"></span>
                                    <div>2019年去甘肃旅游有什么最值得去的景区吗？</div>
                                </div>
                            </div>

                            <div class="title">请避免如下的提问：</div>
                            <div class="wrong-co">
                                <div class="w-li">
                                    <span class="w-icon"></span>
                                    <div>违反法律法规，政治敏感的：例如色情、暴力血腥、求盗版资源等违法内容。</div>
                                </div>
                                <div class="w-li">
                                    <span class="w-icon"></span>
                                    <div>不友善行为：辱骂、人身攻击、地域攻击等引起其他用户不悦等内容。</div>
                                </div>
                                <div class="w-li">
                                    <span class="w-icon"></span>
                                    <div>含有垃圾广告信息等内容。</div>
                                </div>
                                <div class="w-li">
                                    <span class="w-icon"></span>
                                    <div>表意不明确，别人无从回答的。</div>
                                </div>
                                <div class="w-li">
                                    <span class="l-w-icon"></span>
                                    <div>谁有微信号及密码?能用的</div>
                                </div>
                                <div class="w-li">
                                    <span class="l-w-icon"></span>
                                    <div>这样正常吗？</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="new-tip-back" ></span>
                    <span  class="close-icon" @click="close"> </span>
                </div>
            </div>
</template>

<script>
export default {
    methods: {
      async close() {
         this.$emit('closebox'); //触发showbox方法，true为向父组件传递的数据
        }

    }
}
</script>

<style scoped>
.center{
     position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.25);
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* text-align: center; */
    margin: auto;
}
.center1{
    width: 900px;
    height: 12%;
    /* background: rgba(0,0,0,.25); */
    z-index: 1000;
   position: relative;
}
.askq-content-wrap {
    display: inline-block;
    position: absolute;
    /* top:0;
    bottom: 0;
    left: 30%;
    right: 0; */
    margin: auto;
    /* text-align: center; */
    z-index: 1000;
    width: 850px;
    height: 70%;
        /* background: rgba(0,0,0,.25); */
}
/* .askq-content-wrap ::-webkit-scrollbar {
    width: 30px;
    height: 100%;
} */
.askq-content-wrap .newTip-box {
    padding: 33px 20px 35px 60px;
    border-radius: 5px;
    background: #fff;
    width: 750px;
    overflow-y:scroll;
    height: 100%;
    /* border: 1px solid red; */
}
 .new-tip-back {
    /* background: rgba(0,0,0,.25); */
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    /* width: 100%; */
    /* height: 100%; */
    /* z-index: -1; */
    /* border: 1px solid yellowgreen; */
    /* background-color: rebeccapurple; */
    display: inline-block;
    cursor: pointer;
}
 .close-icon {
    height: 15px;
    width: 15px;
    display: inline-block;
    position: absolute;
    top: 50px;
    right: -10px;
    background-size: 20px 20px;
    background-image: url(https://iknowpc.bdimg.com/static/common/widget/css/img/close-icon.20000f5.png);
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: center center;
    /* background: red; */
    cursor: pointer;
}
.askq-content-wrap .newTip-box .new-tip-content {

    padding-top: 10px;
}
.askq-content-wrap .title.top-title {
    margin-top: 0;
}
.askq-content-wrap .title {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-weight: 700;
}
.askq-content-wrap .co {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    margin-top: 16px;
}
.askq-content-wrap .co span {
    color: #151515;
}
.askq-content-wrap .co {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    margin-top: 16px;
}
.askq-content-wrap .co-li {
    margin-bottom: 24px;
}
.askq-content-wrap .co-li .li {
    position: relative;
    margin-top: 8px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    padding-left: 10px;
}
.askq-content-wrap .co-li .li:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #333;
}
.askq-content-wrap .co-li .li span {
    color: #333;
    font-weight: 700;
}
.askq-content-wrap .title {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-weight: 700;
}
.askq-content-wrap .right-co {
    position: relative;
    margin-bottom: 24px;
}
.askq-content-wrap .right-co .r-li {
    margin-top: 14px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    position: relative;
}
.askq-content-wrap .right-co .r-li .r-icon {
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    background: url(https://iknowpc.bdimg.com/static/common/widget/css/img/r-icon.44baa14.png) no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
}
.askq-content-wrap .right-co .r-li div {
    margin-left: 24px;
}
.askq-content-wrap .title {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-weight: 700;
}
.askq-content-wrap .wrong-co {
    position: relative;
    margin-bottom: 24px;
}
.askq-content-wrap .wrong-co .w-li {
    margin-top: 14px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    position: relative;
}
.askq-content-wrap .wrong-co .w-li .w-icon {
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    background: url(https://iknowpc.bdimg.com/static/common/widget/css/img/w-icon.5b7e5d9.png) no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
}
.askq-content-wrap .wrong-co .w-li div {
    margin-left: 24px;
}
</style>