<template>
  <div class="kws">
    <div class="kws-up">
      <span class="explain"> 设置准确的标签可更快找到回答者 </span>
    </div>
    <div class="kws-down">
      <span class="keyword" v-for="(item, index) in keywordList" :key="index">
        <span>{{ item }}</span
        ><span class="kw-close" @click="removeWord(item)"> </span>
      </span>
      <span class="add-kw"
        ><input
          v-model="word"
          placeholder="请输入关键字,回车确定"
          type="text"
          @keyup.enter="addWord"
      /></span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      word: "",
    };
  },
  props: {
    keywordList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async addWord() {
      if (this.word == "") {
        //alert("关键字不能为空！");
        this.$message({
          message: '关键字不能为空！',
          type: 'warning'
        });
        return;
      }
      if (this.keywordList.length == 3) {
        //alert("最多只能添加3个关键字");
        this.$message({
          message: '最多只能添加3个关键字',
          type: 'warning'
        });
        return;
      }
      if (this.word.length > 15) {
        //alert("关键字不能超过15个字！");
        this.$message({
          message: '关键字不能超过15个字！',
          type: 'warning'
        });
        return;
      }
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: this.word,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.word = response.data.message.filterContent;
          this.keywordList.push(this.word);
          this.word = "";
          this.$emit("getKeyword", this.keywordList);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    removeWord(word) {
      this.keywordList.forEach((item, index) => {
        if (item == word) {
          this.keywordList.splice(index, 1);
        }
      });
      this.$emit("getKeyword", this.keywordList);
    },
  },
};
</script>

<style scoped>
input:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
.kws {
  border: 1px solid rgba(210, 210, 210, 1);
  padding: 10px;
  height: 90px;
}
.kws-up {
  padding: 10px;
  font-size: 14px;
}
.kws-down {
  position: relative;
  min-height: 38px;
}
.kws-up .sub-modify {
  float: right;
  color: #0084ff;
  cursor: pointer;
}
.kws-down .keyword {
  display: inline-block;
  height: 20px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 14px;
  padding: 3px 10px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 12px;
}
.kws-down .keyword .kw-close {
  vertical-align: top;
  display: inline-block;
  width: 13px;
  height: 8px;
  line-height: 8px;
  margin-left: 5px;
  background-image: url("../../../../assets/icon/fork.png");
  background-size: 8px 8px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
  right: -5px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.kws-down .keyword .kw-close:hover,
.kws-down .add-kw:hover {
  color: red;
}
.kws-down .add-kw {
  position: absolute;
  right: 0;
  bottom: 4px;
  height: 28px;
  line-height: 28px;
  margin-left: 10px;
  padding: 3px 10px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 12px;
}
.kws-down .add-kw input {
  height: 26px;
  /* border: 1px solid red; */
}
</style>
