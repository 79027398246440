<template>
  <div class="wrap">
    <div style="position:relative;height:100%">
      <V-Help v-show="isShowHelp" @closebox="hideHelp"></V-Help>
    </div>
    <div class="container" @click.stop>
      <div class="ask-label">
        <span class="askquestion-icon"></span>
        <span class="askquestion-font">提问</span>
      </div>
      <div class="help-label">
        <span class="askhelp-icon" @click="showHelp"></span>
      </div>
      <div class="ask-question">
        <textarea
          maxlength="49"
          @blur.prevent="Sensitivequestion(question)"
          style="resize: none"
          v-model="question"
          placeholder="  

            一句话完整描述你的问题  （必填）"
        ></textarea>
        <span class="font-num">{{ question.length }}/49</span>
      </div>
      <div class="ask-content">
        <div class="ask-describe">
          <textarea
            style="resize: none"
            maxlength="5000"
            @blur.prevent="Sensitivedescribe(describe)"
            v-model="describe"
            placeholder="准确地描述问题更容易得到解答,添加优质视频和配图将会得到更多人回答"
          ></textarea>
          <span>{{ describe.length }}字</span>
        </div>
        <span class="hr"></span>
        <div class="ask-opt">
          <!-- <label class="up-file" for="upFile"><input type="file" style="display:none"  id="upFile" ref="file" @change="fileUpload" /></label> -->
          <!-- ////上传视频 -->
          <!-- <label class="up-video" for="upVideo"><input type="file" style="display:none" accept=".mp4,.wmv" id="upVideo" ref="video" @change="videoUpload" /></label>  -->
          <!-- 上传图片 -->
          <!-- 替换自己的上传图标 -->
          <!-- <img class="up-img"  for="upImg"   @click="upLoad"  alt />
                           <input
                              type="file"
                              accept="image/*"
                              @change="changeImage()"
                              ref="avatarInput"
                              style="display:none"
          />-->
          <!-- //////显示图片 -->
          <!-- <div class="pic_list_box">
                <div class="pic_list" v-show="imgDatas"> 
          <div v-for="(src,index) in imgDatas" :key="index">-->
          <!-- <img :src="src" width="80" height="80" alt srcset />  -->
          <!-- 利用element-ui的图片预览插件  -->
          <!-- <el-image
                     style="width: 100px; height: 100px"
                    :src="src"
                    :preview-src-list="imgDatas">
                   </el-image> 
                    <div style="text-align: center;cursor: pointer;font-size:10px" class="qu" @click="deletes(index)">取消</div> 
                  </div> 
               </div>             
          </div>-->
          <!-- 显示视频 -->
          <!-- <div class="pre-list" v-show="preShow">
                <div class="one-v"  v-for=" (item,index) in preShow" :key="index"  style="width:53px; height:53px">
                    <video  :src="item.src" controls="controls" width="100%" height="100%">
                    您的浏览器不支持 video 标签。 
                    </video>
                    <span style="text-align: center;cursor: pointer;font-size:10px" @click="deleteThis(item.name)">取消</span>
                </div>

          </div>-->
          <!-- <span class="postion" v-else-if="dialogVideo==''" style="font-size:18px">图片和视频均不能超过三张</span> -->
          <!-- 上传图片 -->
          <el-tooltip class="item" effect="dark" content="添加图片" placement="top">
            <div class="picture">
              <el-upload
                class="Imege"
                :action="address"
                accept=".png, .jpeg, .jpg"
                :show-file-list="false"
                :before-upload="beforeUploadVideoIMG"
                :on-success="handleVideoSuccessIMG"
                :on-progress="uploadVideoProcessIMG"
              >
                <img
                  v-if="dialogImageUrl != '' && videoFlagIMG == false"
                  :src="API + dialogImageUrl"
                  width="350"
                  height="180"
                  controls="controls"
                  style="margin-right:20px"
                  class="putr"
                />

                <i v-else-if="dialogImageUrl == '' && videoFlagIMG == false" class="up-img"></i>

                <el-progress
                  v-if="videoFlagIMG == true"
                  type="circle"
                  :percentage="videoUploadPercentIMG"
                  style="margin-top:30px"
                ></el-progress>
              </el-upload>
              <span v-if="dialogImageUrl != ''" class="detesed">
                <span @click="detes" class="detes"></span>
              </span>
              <!-- <el-upload
                     :action="address"
                     list-type="picture-card"
                     :on-preview="handlePictureCardPreview"
                     :on-remove="handleRemove"
                     :on-success="PICsuccess"
 :file-list="fileList"
                     :limit="1"
                     class="Imege"
                     >
                    <i class="up-img"></i>
              </el-upload>-->
              <!-- :before-upload="beforeAvatarUploadImg" -->
              <!-- img-src -->
              <!-- <el-dialog :visible.sync="dialogVisible"> -->
              <!-- <img width="100%" :src="dialogImageUrl" alt=""> -->
              <!-- <img width="100%" :src="fileList[0].url" alt="">
              </el-dialog>-->
            </div>
          </el-tooltip>
          <!-- 上传视频 -->
          <el-tooltip class="item" effect="dark" content="添加视频" placement="top">
            <div class="video">
              <el-upload
                class="Imege"
                :action="address"
                accept=".mp4, .MP4, .qlv, .qsv, .ogg, .flv, .avi, .wmv, .rmvb"
                :show-file-list="false"
                :before-upload="beforeUploadVideo"
                :on-success="handleVideoSuccess"
                :on-progress="uploadVideoProcess"
              >
                <video
                  v-if="dialogVideo != '' && videoFlag == false"
                  :src="API + dialogVideo"
                  width="350"
                  height="180"
                  controls="controls"
                  class="videoss"
                >您的浏览器不支持视频播放</video>

                <i v-else-if="dialogVideo == '' && videoFlag == false" class="up-video"></i>

                <el-progress
                  v-if="videoFlag == true"
                  type="circle"
                  :percentage="videoUploadPercent"
                  style="margin-top:30px"
                ></el-progress>
              </el-upload>
              <span v-if="dialogVideo != ''" class="detesed">
                <span @click="dete" class="detes"></span>
              </span>
              <!-- <el-upload
                      :action="address"
                     list-type="picture-card"
                     :on-preview="handlevideoCardPreview"
                     :on-remove="handleRemovevido"
                     :on-success="videosuccess"
                      :file-list="fileList1"
                     :limit="1"
                     class="Imege"
                     >
                    <i  class="up-video"></i>
              </el-upload>-->
              <!-- :before-upload="beforeAvatarUploadImg1" -->
              <!-- <el-dialog :visible.sync="dialogVideoVisible">
                   <video   width="100%" :src="dialogVideo" alt="">您的浏览器不支持视频播放   </video>
              </el-dialog>-->
              <!-- v-bind:src="dialogVideo" -->
              <!-- <video v-if="dialogVideoVisible "
                             v-bind:src="fileList1[0].url"
                           class="avatar video-avatar"
                           controls="controls">
                        您的浏览器不支持视频播放
              </video>-->
            </div>
          </el-tooltip>
          <!-- <span class="postion"  style="font-size:13px">友情提示：图片和视频均为一个,
          请先移动鼠标删除示例图片再上传</span>-->
        </div>
      </div>
      <div class="ask-keywords">
        <span class="siz">请选择问题的分类：</span>
        <el-select v-model="teamTypes" placeholder="请选择分类" class="input">
          <!-- <el-option label="计算机科学" value="计算机科学"></el-option>
                           <el-option label="电子电工" value="电子电工"></el-option>
                           <el-option label="材料科学" value="材料科学"></el-option>
                           <el-option label="人工智能" value="人工智能"></el-option>
                           <el-option label="机械工程" value="机械工程"></el-option>
                           <el-option label="合成生物学" value="合成生物学"></el-option>
                           <el-option label="图书情报" value="图书情报"></el-option>
          <el-option label="教育学" value="教育学"></el-option>-->

          <el-option v-for="item in teamType" :label="item" :key="item.id" :value="item"></el-option>
        </el-select>
        <span style="color:red;font-size:15px;margin-left:5px">*</span>
      </div>
      <div class="ask-keyword">
        <V-KeywordSetting :keywordList="keywords"></V-KeywordSetting>
      </div>
      <div class="wenti">
        <span class="askhelp-icon" @click="open1"></span>
      </div>
      <div class="ask-questionsetting">
        <V-QuestionSetting
          :shared="qsetting.shared"
          :reward="qsetting.reward"
          :solve="qsetting.solve"
        ></V-QuestionSetting>
      </div>
      <div class="ask-bottom">
        <a href="javaScript:void(0)" @click="backhome" class="publish-btns">取消发布</a>
        <a href="javaScript:void(0)" @click="Submits" class="publish-btns">
          {{
          publishBtns
          }}
        </a>
        <a href="javaScript:void(0)" @click="sendQuestions" class="publish-btn">{{ publishBtn }}</a>
      </div>
      <div></div>
    </div>
    <!-- 正在加载中 -->
    <div v-if="loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>
<script>
import Help from "@/views/wisdom-answer/ask-question/help/index.vue";
import KeywordSetting from "@/views/wisdom-answer/ask-question/keyword-setting/index.vue";
import QuestionSetting from "@/views/wisdom-answer/ask-question/question-setting/index.vue";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import { integralAddOne } from "@/js/common";
import { DELETE, QUERY, INSERT, UPDATE, QUERYED } from "@/services/dao.js";
import axios from "axios";
export default {
  data() {
    return {
      loading: false, //是否显示正在加载中
      id: -1,
      question: "", //问题
      describe: "", //问题描述
      file: [], //文件
      upFiles: [], //上传文件信息,
      keywords: [], //关键词
      publishBtn: "预览问题",
      publishBtns: "发布",
      teamType: [], //问题分类
      teamTypes: "", // 问题的分类
      qsetting: {
        shared: {
          type: 1, //分成 1-免费 2-分成
          pay: 0,
          release: 0,
          answer: 0
        },
        reward: {
          type: 1, //悬赏类型 1-免费 2-竞价成交 3-抢答成交 4-信用度成交 5选择成交
          pay: 0,
          endTime: ""
        },
        solve: {
          type: 1,
          startTime: "",
          endTime: "",
          adress: ""
        }
      }, //问题设置
      isModify: false,
      isShowHelp: false,
      imgDatas: [],
      dialogImageUrl: [], //图片
      dialogVisible: false,
      dialogVideo: [], //视频
      dialogVideoVisible: false,
      address: "", //访问地址
      API: "", // 上传的图片地址暂存
      fileList: [
        {
          name: " ",
          url: " "
        }
      ],
      fileList1: [
        {
          name: " ",
          url: " "
        }
      ],
      videoFlag: false, //刚开始的时候显示为flase(视频)
      videoFlagIMG: false, //刚开始的时候显示为flase（图片）
      videoUploadPercent: "0%", //进度条刚开始的时候为0%(视频)
      videoUploadPercentIMG: "0%", //进度条刚开始的时候为0%(图片)
      centerss: []
      // imgdete:false,//图片的删除
    };
  },
  created() {
    this.address = this.api.LoginURL.concat(
      "/resources/trading/resourcesUpload"
    );
    this.API = this.api.LoginURL.concat();
    //alert(this.api.LoginURL.concat())
    //加载显示返回数据
    this.init();
    //加载显示分类
    this.inii();
  },
  mounted() {
    // 预览提问返回后重新渲染填写信息
    let data = JSON.parse(sessionStorage.getItem("askQuestion"));
    if (data != null) {
      // console.log(data);
      this.question = data.question; // 问题
      this.describe = data.describe; // 描述
      this.keywords = data.keywords; // 精确的标签
      this.teamTypes = data.teamTypes; // 问题的分类
      this.dialogImageUrl = data.dialogImageUrl; // 图片地址
      this.dialogVideo = data.dialogVideo; // 视频地址
      // 分成
      this.qsetting.shared.type = data.qsetting.shared.type; // 分成类型
      this.qsetting.shared.pay = data.qsetting.shared.pay; // 支付的知识币
      this.qsetting.shared.release = data.qsetting.shared.release; // 比例
      this.qsetting.shared.answer = data.qsetting.shared.answer; // 比例
      // 悬赏
      this.qsetting.reward.type = data.qsetting.reward.type; // 悬赏类型
      this.qsetting.reward.pay = data.qsetting.reward.pay; // 悬赏的知识币
      this.qsetting.reward.endTime = data.qsetting.reward.endTime; // 悬赏截止时间
      // 求解方式
      this.qsetting.solve.type = data.qsetting.solve.type; // 求解类型
      this.qsetting.solve.startTime = data.qsetting.solve.startTime; // 求解开始时间
      this.qsetting.solve.endTime = data.qsetting.solve.endTime; // 求解结束时间
      this.qsetting.solve.adress = data.qsetting.solve.adress; // 求解地点
    }
  },
  computed: {
    // preShow:function(){
    //     let file=[];
    //     this.file.forEach((item, index) => {
    //         if(item.type=="video/mp4"){
    //          let url = URL.createObjectURL(item);
    //           file.push({type:"video",src:url,name:item.name});
    //         }
    //     });
    //     return file
    // },
  },
  methods: {
    open1() {
      const h = this.$createElement;

      this.$notify({
        title: "查看分成说明：",
        message: h("i", { style: "color: teal" }, "查看分成由提问者设置分成比例，，主要用于保护回答者和提问者的知识产权。针对出提问者和被采纳的回答者（以下简称：被采纳者）以外的其他用户，在查看当前问题的答案需要支付知识币，平台将按照提问者设置的比例对知识币进行分发到提问者和被采纳者的账户；免费问题存在无被采纳者，所以不能设置查看分成。")
      });
    },
    /////问题标题
    async Sensitivequestion(name) {
      await this.www(name);
      this.question = this.centerss;
    },
    // 问题内容
    async Sensitivedescribe(name) {
      await this.www(name);
      this.describe = this.centerss;
    },
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centerss = response.data.message.filterContent;
          console.log("赋值成功：" + this.centerss);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: "系统正在加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      setTimeout(() => {
        loading.close();
      }, 3000);
    },
    ///////上传视频
    dete() {
      this.dialogVideo = "";
    },
    beforeUploadVideo(file) {
      //视频上传之前判断他的大小
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过2000MB哦!");
        return false;
      }
    },
    uploadVideoProcess(event, file, fileList) {
      //视频上传的时候获取上传进度传给进度条
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage);
      console.log(this.videoUploadPercent);
    },
    handleVideoSuccess(file) {
      //视频上传成功之后返回视频地址
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      console.log(file);
      this.dialogVideo = file.data.originalFileUrl;
      console.log("视频上传成功" + this.dialogVideo);
    },
    //////////////////上传图片
    detes() {
      this.dialogImageUrl = "";
    },
    beforeUploadVideoIMG(file) {
      //图片上传之前判断他的大小
      const isLt10M = file.size / 1024 / 1024 < 2000;
      if (!isLt10M) {
        this.$message.error("上传视频大小不能超过2000MB哦!");
        return false;
      }
    },
    uploadVideoProcessIMG(event, file, fileList) {
      //图片上传的时候获取上传进度传给进度条
      this.videoFlagIMG = true;
      this.videoUploadPercentIMG = parseInt(file.percentage);
      console.log(this.videoUploadPercentIMG);
    },
    handleVideoSuccessIMG(file) {
      //图片上传成功之后返回视频地址
      this.videoFlagIMG = false;
      this.videoUploadPercentIMG = 0;
      console.log(file);
      this.dialogImageUrl = file.data.originalFileUrl;
      console.log("图片上传成功" + this.dialogImageUrl);
    },
    //     // 文件列表移除文件时的钩子(图片)
    //     handleRemove(file, fileList) {
    //         this.dialogImageUrl="";
    //          console.log(file, fileList);
    //         },
    //                 // 文件列表移除文件时的钩子(视频)
    //     handleRemovevido(file, fileList) {
    //         this.dialogVideo="";
    //         console.log(file, fileList);
    //         },
    //   //点击文件列表中已上传的文件时的钩子（图片）
    //   handlePictureCardPreview(file) {
    //     this.dialogImageUrl = file.url;
    //     this.dialogVisible = true;
    //   },
    //    //点击文件列表中已上传的文件时的钩子（视频）
    //   handlevideoCardPreview(file){
    //      this.dialogVideo = file.url;
    //     this.dialogVideoVisible = true;
    //   },
    //   //上传图片成功后返回的数据（图片）
    //   PICsuccess(file){
    //     this.dialogImageUrl=file.data.url;
    //     console.log('上传返回的图片路径：'+this.dialogImageUrl)
    //   },
    //   //上传视频后返回的数据(视频)
    //   videosuccess(file){
    //     this.dialogVideo=file.data.url;
    //     console.log('上传返回的视频路径：'+this.dialogVideo)
    //   },
    //   上传之前的回调（视频）
    // async beforeAvatarUploadImg1(file){
    //      const isJPG1 = file.type ==='video/x-ms-wmv';
    //      const isJPG2= file.type ==='video/mp4';
    //      const isJPG3= file.type ==='video/Ogg';
    //      const isJPG4= file.type ==='video/baiMPEG4';
    //      const isJPG5= file.type ==='video/WebM';
    //     const isLt2M = file.size / 1024 / 1024 < 20;

    //     if (!isJPG1||!isJPG2||!isJPG3||!isJPG4||!isJPG5) {
    //       this.$message.error('格式不正确');
    //     }
    //      if (!isLt2M) {
    //       this.$message.error('上传头像图片大小不能超过 20MB!');
    //     }
    //     return isJPG1 && isLt2M;
    // },
    //   ////上传之前的回调事件(图片)
    //   async beforeAvatarUploadImg(file){
    //      const isJPG1 = file.type === 'image/jpeg';
    //      const isJPG2= file.type ==='image/jpg';
    //      const isJPG3= file.type ==='image/png';
    //      const isJPG4= file.type ==='image/PBG';
    //      const isJPG5= file.type ==='image/JPG';
    //     const isLt2M = file.size / 1024 / 1024 < 4;

    //     if (!isJPG1||!isJPG2||!isJPG3||!isJPG4||!isJPG5) {
    //       this.$message.error('格式不正确');
    //     }
    //      if (!isLt2M) {
    //       this.$message.error('上传头像图片大小不能超过 4MB!');
    //     }
    //     return isJPG1 && isLt2M;
    //   },
    //     //移除文件
    //     removeFile(index){
    //         this.upFiles.splice(index, 1);
    //     },
    //         changeImage() {
    //   // 上传图片事件
    //   var files = this.$refs.avatarInput.files;
    //   var that = this;
    //   function readAndPreview(file) {
    //     //Make sure `file.name` matches our extensions criteria
    //     if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
    //       var reader = new FileReader();
    //       reader.onload = function(e) {
    //         // 防止重复上传
    //         if (that.imgDatas.indexOf(e.target.result) === -1) {
    //           that.imgDatas.push(e.target.result);

    //         }
    //       };
    //       reader.readAsDataURL(file);
    //     }
    //   }
    //   readAndPreview(files[0])
    //   if (files.length === 0) {
    //     return;
    //   }

    //   // 文件上传服务器
    //   // this.setUploadFile(files[0])

    // },

    ////加载分类
    async inii() {
      let info = await QUERYED("post", "", "TYPE {id  type}");
      //   console.log(info)
      for (let i = 0; i < info.data.TYPE.length; i++) {
        this.teamType.push(info.data.TYPE[i].type);
      }
    },
    upLoad() {
      // 触发上传图片按钮
      this.$refs.avatarInput.dispatchEvent(new MouseEvent("click"));
    },
    deletes(index) {
      this.imgDatas.splice(index, 1);
      return;
    },
    //预览问题
    async sendQuestions() {
      let dataObj = {
        question: this.question, //标题
        describe: this.describe, //内容

        dialogImageUrl: this.dialogImageUrl, //图片地址
        dialogVideo: this.dialogVideo, // 视频地址

        teamType: this.teamType, //分类
        keywords: this.keywords, //标签
        qsetting: this.qsetting, //悬赏、解答方式、分成
        teamTypes: this.teamTypes // 问题分类
      };
      window.sessionStorage.setItem("askQuestion", JSON.stringify(dataObj));
      this.$router.push({
        path: "/previews",
        query: {
          question: this.question, //标题
          describe: this.describe, //内容
          dialogImageUrl: this.dialogImageUrl, //图片
          dialogVideo: this.dialogVideo, //视频
          teamType: this.teamType, //分类
          keywords: this.keywords, //标签
          qsetting: this.qsetting, //悬赏、解答方式、分成
          teamTypes: this.teamTypes // 问题分类
        }
      });
    },
    backhome() {
      sessionStorage.removeItem("askQuestion");//清空
      this.$router.push({
        path: "/qaIndex"
      });
    },
    //显示帮助
    showHelp() {
      this.isShowHelp = true;
    },
    //隐藏帮助
    hideHelp() {
      this.isShowHelp = false;
    },
    
    //执行提交
    async sendQuestion() {
      ///如果没有上传文件
      if (this.upFiles.length <= 0) {
        this.Submits();
        return;
      } else {
        var files = this.upFiles;
        let formdata = new FormData();
        formdata.append("file", files[0]);

        axios({
          url: "http://192.168.1.120:9527/resources/trading/resourcesUpload",
          data: formdata,
          method: "post"
        })
          .then(resp => {
            console.log(resp);
            //alert(JSON.stringify(JSON.stringify(resp.data)))
            this.upFiles = resp.data.data.url;
            //alert(this.upFiles)
            this.Submit();
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    //显示预览关闭的
    async init() {
      // if(this.$route.params.question.length!="undefined"||this.$route.params.keywords!="undefined"||this.$route.params.teamType!="undefined"||this.$route.params.describe.length!="undefined"||this.$route.params.question!="undefined"||this.describe!="undefined"){
      //       this.question=this.$route.params.question;
      //       this.describe=this.$route.params.describe;
      //       this.question.length=this.$route.params.question.length;
      //       this.describe.length=this.$route.params.describe.length;
      //       this.keywords=this.$route.params.keywords;
      //       this.teamType=this.$route.params.teamType;
      //       this.qsetting.reward.pay=this.$route.params.qsetting.reward.pay;
      //       this.qsetting.reward.endTime=this.$route.params.qsetting.reward.endTime;
      //       this.qsetting.solve.startTime=this.$route.params.qsetting.solve.startTime;
      //       this.qsetting.solve.endTime=this.$route.params.qsetting.solve.endTime;
      //       this.qsetting.shared.type=this.$route.params.qsetting.shared.type;
      //       this.qsetting.shared.pay=this.$route.params.qsetting.shared.pay;
      //       this.qsetting.reward.type=this.$route.params.qsetting.reward.type;
      //       this.qsetting.solve.type=this.$route.params.qsetting.solve.type;
      //       this.qsetting.solve.adress=this.$route.params.qsetting.solve.adress;
      //alert(this.$route.params.teamType)
      //alert(this.$route.params.question.length)
      // }
      //
    },
    async Submits() {
      ////获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      console.log("2222");
      if (
        this.user == null ||
        this.user == "" ||
        this.user == "Undefined" ||
        this.user <= 0
      ) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！"
        });
      } else {
        if (this.teamTypes == "" || this.teamTypes == null) {
          this.$message({
            type: "warning",
            message: "问题分类为必填项！"
          });
        } else if (this.question == "" || this.question == null) {
          this.$message({
            type: "warning",
            message: "标题为必填项！"
          });
        } else if (this.qsetting.solve.type == 2) {
          console.log("22223");
          ///判断是否选中线下
          if (
            this.qsetting.solve.startTime == "" ||
            this.qsetting.solve.endTime == ""
          ) {
            this.$message({
              type: "warning",
              message: "线下求解的求解起始时间为必填哦！"
            });
          } else {
            this.add(this.user); //发布
          }
        } else {
          //  判断是否是免费
          console.log("222235");
          if (this.qsetting.reward.type == 1) {
            this.add(this.user); //发布
          } else {
            //    判断是否选了截止时间
            if (this.qsetting.reward.endTime == "") {
              this.$message({
                type: "warning",
                message: "悬赏收费问题截止时间必填哦！"
              });
            }
            //悬赏价格
            else if (
              this.qsetting.reward.pay == "" ||
              this.qsetting.reward.pay == 0
            ) {
              this.$message({
                type: "warning",
                message: "收费问题的金额不能为空/0哦！"
              });
            } else if (this.qsetting.shared.type == 2) {
              //如果设置了分成
              if (this.qsetting.shared.answer == 0) {
                this.$message({
                  type: "warning",
                  message: "选择了查看分成后回答被采纳者的比例必填哦！"
                });
              } else if (this.qsetting.shared.release == 0) {
                this.$message({
                  type: "warning",
                  message: "选择了查看分成后问题发布者的比例必填哦！"
                });
              } else if (
                this.qsetting.shared.pay == "" ||
                this.qsetting.shared.pay == 0
              ) {
                //如果没有设置查看分成的金额
                this.$message({
                  type: "warning",
                  message: "选择了查看分成的查看方式不能为空/0！"
                });
              } else {
                this.add(this.user); //发布
              }
            } else {
              this.add(this.user); //发布
            }
          }
          //新增提交
          // this.add(this.user);
        }
      }
    },
    //发布成功
    async add(user) {
      this.loading = true; ///显示
      // console.log(
      //   "价格：" + this.qsetting.shared.pay,
      //   "类别：" + this.teamTypes,
      //   "视频：" + this.dialogVideo,
      //   "图片：" + this.dialogImageUrl,
      //   "求解地点：" + this.qsetting.solve.adress,
      //   "问题ID：" + user,
      //   "求解结束时间：" + this.qsetting.solve.endTime,
      //   "求解开始时间：" + this.qsetting.solve.startTime,
      //   "时间：" + this.qsetting.shared.type,
      //   "悬赏：" + this.qsetting.reward.pay,
      //   "标题：" + this.question,
      //   "内容：" + this.describe,
      //   "回答者：" + this.qsetting.shared.answer,
      //   "发布者比例" + this.qsetting.shared.release
      // );
      let info = await INSERT(
        "POST",
        "",
        '  insert_WdQuestion(objects: {viewPrice:"' +
          this.qsetting.shared.pay +
          '" ,problemClassification :"' +
          this.teamTypes +
          '",video :"' +
          this.dialogVideo +
          '", img :"' +
          this.dialogImageUrl +
          '",answerAdress:"' +
          this.qsetting.solve.adress +
          '", questioner:' +
          user +
          '  , answerEndTime:"' +
          this.qsetting.solve.endTime +
          '" , answerStartTime:"' +
          this.qsetting.solve.startTime +
          '" , answerWay:' +
          this.qsetting.solve.type +
          ' , questionTitle: "' +
          this.question +
          '", questionContent: "' +
          this.describe +
          '", questionerPercentage: "' +
          this.qsetting.shared.release +
          '", mediaPathArray: "", keyword: ' +
          JSON.stringify(JSON.stringify(this.keywords)) +
          ', answerDeadlineTime: "", answererPercentage: "' +
          this.qsetting.shared.answer +
          '", competeDeadlineTime: "' +
          this.qsetting.reward.endTime +
          '", competeMode: ' +
          this.qsetting.reward.type +
          ", competePriceSetting: " +
          this.qsetting.reward.pay +
          ', createTime: "' +
          new Date() +
          '", isViewFree: ' +
          this.qsetting.shared.type +
          "}) {affected_rows returning {       id     }}"
      );
      if (
        info.data.insert_WdQuestion &&
        info.data.insert_WdQuestion.affected_rows > 0
      ) {
        this.$message({
          message: "发布成功！",
          type: "success"
        });
        this.$router.push({
          path: "/askPreview",
          query: {
            id: info.data.insert_WdQuestion.returning[0].id,
            teamTypes: this.teamTypes
          }
        });

        ///提交成功该用户积分加1
        integralAddOne(this.user);
        // 提交成功 则清空sessionStorage的缓存
        sessionStorage.removeItem("askQuestion");
        this.loading = false; //隐藏
      } else {
        this.$message.error("发布失败！");
      }
    },
    //删除图片视频
    // deleteThis(name){
    //      this.file.forEach((item, index) => {
    //         if(item.name==name){
    //              this.file.splice(index, 1);
    //             return;
    //         }
    //     });
    // },
    //视频上传
    // videoUpload(e){
    //     let inputDOM = this.$refs.video;
    //     console.log(inputDOM.files[0]);
    //     if(inputDOM.files[0].type=="video/x-ms-wmv"||inputDOM.files[0].type=="video/mp4"||inputDOM.files[0].type=="video/x-ms-wmv"){
    //         this.fileLimit(inputDOM.files[0]);
    //     }else{
    //         alert("格式不正确,请上传视频文件");
    //     }
    // },
    //图片上传
    // imgUpload(e){
    //     let inputDOM = this.$refs.img;
    //     if(inputDOM.files[0].type=="image/png"||inputDOM.files[0].type=="image/jpeg"||inputDOM.files[0].type=="image/bmp"){
    //         this.fileLimit(inputDOM.files[0]);
    //     }else{
    //          alert("格式不正确,请上传图片文件");
    //     }
    // },
    // fileLimit(upFile){
    //     let isRepeat=false;//判断重复
    //     let totleSize=0;    //总的字节数
    //     let fileLen=0;     //文件总个数
    //     if( this.file.length==0){
    //             totleSize=this.file.size;
    //     }else{
    //         this.file.forEach((item, index) => {
    //             if(item.name==upFile.name&&item.type==upFile.type){
    //                 isRepeat=true;
    //             }
    //             totleSize=totleSize+item.size;
    //             fileLen++;
    //         });
    //     }
    //     if(isRepeat){
    //             alert("存在重复文件");
    //             return;
    //     }
    //     if(fileLen>=7){
    //         alert("不能继续上传,文件个数已大于7");
    //         return;
    //     }
    //     if(totleSize>200*1024*1024){
    //             alert("文件总大超过200MB");
    //             return;
    //     }
    //     this.file.push(upFile);
    // },
    dataFormat(time) {
      return `${time.getFullYear()}-${
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }-${time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()} ${
        time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes()
      }:${
        time.getSeconds() >= 10 ? time.getSeconds() : "0" + time.getSeconds()
      }`;
    }
  },
  components: {
    "V-Help": Help,
    "V-KeywordSetting": KeywordSetting,
    "V-QuestionSetting": QuestionSetting,
    "V-Loading": Loading //正在加载中
  }
};
</script>

<style scoped>
.picture {
  /* width: 50%; */
  /* border: 1px solid red; */
  /* text-align: right; */
  /* float: right; */
  display: inline-block;
}
.postion {
  /* width: 40%; */
  /* border: 1px solid red; */
  text-align: right;
  display: inline-block;
  height: 20px;
  /* width: 300px; */
  position: relative;
  /* left: 200px; */
  top: -10px;
  color: darkgrey;
  /* padding-right: 30px; */
  /* right: -300px; */
}
.video {
  /* width: 50%; */
  /* border: 1px solid firebrick; */
  /* text-align: right; */
  /* float: right; */
  display: inline-block;
  /* border: 1px solid red; */
}
.wrap {
  background-color: #f7f7f7;
  padding: 50px 0;
}
.container {
  margin: 0px auto;
  padding: 70px 50px;
  width: 786px;

  background-color: #ffffff;
}
textarea {
  padding: 0;
  border: 0;
  margin: 0;
}
.ask-label .askquestion-icon {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-image: url("~@/assets/icon/ask.png");
  vertical-align: middle;
  background-repeat: no-repeat;
}
.ask-label .askquestion-font {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(150, 150, 150, 1);
  vertical-align: middle;
}
.siz {
  height: 50px;
  line-height: 50px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: black;
  vertical-align: middle;
}
.ask-question {
  position: relative;
  height: 74px;
  /* border:1px solid rgba(0,132,255,1); */
  border-radius: 3px;
}

.ask-question textarea {
  width: 98.4%;
  height: 95%;
  font-size: 13px;
  padding: 5px;
  border: 1px solid rgba(0, 132, 255, 1);
}
.help-label {
  text-align: right;
}
.askhelp-icon {
  display: inline-block;
  height: 12px;
  width: 12px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/help.png");
}
.ask-question .font-num {
  position: absolute;
  bottom: 0;
  right: 8px;
}
.ask-content {
  padding: 10px;
  margin-top: 25px;
  /* height: 330px; */
  border: 1px solid rgba(220, 220, 220, 1);
}
.hr {
  width: 100%;
  height: 1px;
  display: inline-block;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
}
.ask-content .ask-describe {
  height: 240px;
  position: relative;
  margin-bottom: 6px;
}
.ask-content .ask-describe textarea {
  height: 98%;
  width: 99%;
  font-size: 13px;
  padding: 5px;
  /* border:1px solid rgba(220,220,220,1); */
}
.ask-content .ask-describe span {
  position: absolute;
  bottom: 0;
  right: 0;
}
.ask-opt {
  padding: 20px;
  /* border: 1px solid red; */
  /* display: inline-block; */
}
/* .putr:hover{
 
   } */
.detesed {
  width: 93%;
  height: 20px;
  /* border: 1px solid forestgreen; */
  display: inline-block;
  text-align: center;
}
.detes {
  width: 20px;
  height: 20px;
  text-align: center;
  display: inline-block;
  background-image: url("../../../assets/icon/delete.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;
  top: 7px;
  /* border: 1px solid red; */
  cursor: pointer;
}
.ask-opt > label,
.ask-opt > div {
  vertical-align: top;
}
.ask-keyword {
  padding: 20px 0;
}
.ask-opt > .tip-info {
  display: inline-block;
  margin-left: 230px;
  font-size: 12px;
  line-height: 24px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(79, 79, 79, 1);
}
.ask-opt .up-video {
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-video.png);
  background-repeat: no-repeat;
  background-position-x: 16px;
  background-position-y: 13px;
  /* position: relative;
        left: -10px;
        top: -6px; */
  cursor: pointer;
  background-color: white;
}
.ask-opt .up-video:hover,
.ask-opt .up-img:hover {
  background-color: rgba(210, 210, 210, 1);
}
.ask-opt .up-img {
  margin-left: 10px;
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-img.png);
  background-repeat: no-repeat;
  background-position-x: 14px;
  background-position-y: 10px;
  /* position: relative;
        left: -20px;
        top: -6px; */
  background-color: white;
  cursor: pointer;
  margin-right: 20px;
}
.ask-opt > span {
  margin-left: 6px;
  font-size: 14px;
  color: rgba(150, 150, 150, 1);
  vertical-align: bottom;
}
.publish-btn {
  text-align: center;
  line-height: 34px;
  display: inline-block;
  width: 88px;
  height: 34px;
  background: rgba(0, 132, 255, 1);
  border: 1px solid rgba(0, 132, 255, 1);
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  color: #ffffff;
}
.publish-btns {
  text-align: center;
  line-height: 34px;
  display: inline-block;
  width: 88px;
  height: 34px;
  background: rgba(0, 132, 255, 1);
  border: 1px solid rgba(0, 132, 255, 1);
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  color: #ffffff;
  margin-right: 20px;
}
.ask-bottom {
  /* margin-top: 20px; */
  text-align: right;
}
.ask-action {
  margin-top: 16px;
  margin-left: -5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: rgba(90, 90, 90, 1);
  line-height: 24px;
}
.ask-action div:not(:first-of-type) {
  margin-left: 12px;
}
.pre-list {
  display: inline-block;
  margin-left: 5px;
  width: 55px;
  height: 80px;
}
.remove-file {
  color: red;
  cursor: pointer;
  margin-right: 5px;
  line-height: 23px;
  font-size: 10px;
}
.remove-file:hover {
  color: rosybrown;
  font-size: 10px;
}
.pre-list .one-btn {
  text-align: center;
  margin-left: 0px;
  font-size: 12px;
  color: rgba(150, 150, 150, 1);
  cursor: pointer;
}
.up-file {
  margin-top: 10px;
  display: inline-block;
  width: 58px;
  height: 51px;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-file.png);
  background-repeat: no-repeat;
  background-position-x: 14px;
  background-position-y: 10px;
  cursor: pointer;
}
.up-file:hover {
  border-color: rgb(241, 233, 233);
  background-color: rgb(241, 233, 233);
}
.pre-list .one-btn:hover {
  color: red;
}
.pre-list:first-of-type {
  margin-left: 30px;
}
.ask-questionsetting {
  min-height: 250px;
  /* border: 1px solid red; */
}
.ask-keywords {
  padding: 10px;
  margin-top: 25px;
  width: 765px;
  height: 50px;
  border: 1px solid rgba(220, 220, 220, 1);
}

.pic_list_box {
  display: flex;
}
.pic_list_box {
  display: flex;
}
.pic_list {
  display: flex;
}
.pre-list {
  display: inline-block;
  margin-left: 5px;
  width: 55px;
  height: 80px;
  /* margin-top: 5px; */
  position: relative;
  top: 16px;
}
.pre-list .one-btn:hover {
  color: red;
}
.pre-list:first-of-type {
  margin-left: 30px;
}
.one-btn {
  text-align: center;
  margin-left: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  color: rgba(150, 150, 150, 1);
  cursor: pointer;
}
textarea:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
input:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
.videoss {
  /* border: 1px solid red; */
  object-fit: cover;
}
.wenti {
  width: 100%;
  /* background-color: blueviolet; */
  text-align: right;
}
</style>
